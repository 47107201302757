<template>
  <WidgetFrame>
    <template v-slot:title>
      {{ $t('dashboard.task_alerts') }}
    </template>
    <template v-slot:content>
      <div v-if="!canView('PROJECT', ['TASK']) || !canView('STAFF')" class="center-text">
        {{ $t('entity_selector.error.insufficient_permission_to_show_data') }}
      </div>
      <template v-else-if="!loaded">
        <div class='status-message'>Loading...</div>
      </template>
      <template v-else>
        <div class="settings">
            <b-form-checkbox v-model="showWarnings">{{ $t('dashboard.show_warnings') }}</b-form-checkbox>          
            <b-form-checkbox v-model="showCompleted">{{ $t('dashboard.show_completed_tasks') }}</b-form-checkbox>
        </div>
        <TaskAlertList v-if="loaded" :tasks="alertTasks" @editTask='editTask' />
      </template>
    </template>
  </WidgetFrame>
</template>
<script>
  import * as moment from 'moment-timezone';
  moment.tz.setDefault('Etc/UTC');
  import { taskService } from '@/services';
  
  export default {
    name: 'ProjectTaskAlertsWidget',
    components: {
      WidgetFrame: () => import("@/components/Dashboard/WidgetFrame"),
      TaskAlertList: () => import('@/components/Dashboard/Widget/List/TaskAlertList.vue'),
    },
    props: {
      profile:    { type: Object, required: true },
      project:    { type: Object, required: true },
      refresh:    { type: Boolean, default: false }
    },
    watch: {
      refresh(newValue, oldValue) {
        if (newValue) {
          this.tasks = null;
          this.buildData();
          this.$emit('refreshed');
        }
      },
      showWarnings(newValue, oldValue) {
        if (oldValue != null) {
          this.saveSettings();
          this.buildData();
        }
      },
      showCompleted(newValue, oldValue) {
        if (oldValue != null) {
          this.saveSettings();
          this.buildData();
        }
      },
    },
    data() {
      return {
        loaded: false,
        showWarnings: null,
        showCompleted: null,
        alertTasks: [],
        tasks: null
      };
    },
    created() {
      if ('showWarnings' in this.profile) {
        this.showWarnings = this.profile.showWarnings;
      } else {
        this.showWarnings = true;
      }
      if ('showCompleted' in this.profile) {
        this.showCompleted = this.profile.showCompleted;
      } else {
        this.showCompleted = true;
      }      
      this.buildData();
    },
    methods: {
      async buildData() {
        this.alertTasks = [];
        const self = this;
        const red = 'var(--status-red)';
        const orange = 'var(--status-orange)';
        const typeCritical = "critical";
        const typeWarning = "warning";
        const criticalFilter = ["_or_",
        [
          "_and_",
          [
            ["PROJECT.TASK.startTime", "lt", "<NOW>"],
            ["PROJECT.TASK.progress", "eq", 0],
            ["PROJECT.TASK.taskType", "eq", 'Task']
          ],
          "_and_",
          [
            ["PROJECT.TASK.closeTime", "lt", "<NOW>"],
            ["PROJECT.TASK.progress", "neq", 1],
            ["PROJECT.TASK.taskType", "eq", 'Task']
          ],
          "_and_",
          [
            [["=calc(A-B)", ["PROJECT.TASK.actualCost"], ["PROJECT.TASK.estimatedCost"] ] , "gt", 0],
            ["PROJECT.TASK.taskType", "eq", 'Task']
          ]
        ]];
        const warningFilter = [
          "_or_",
          [
            "_and_",
            [
                [
                    [
                        "=aggregate(A,B,C)",
                        [
                            "PROJECT.TASK"
                        ],
                        [
                            "STAFF.uuId",
                            0,
                            "float"
                        ],
                        "count"
                    ],
                    "eq",
                    0
                ]
            ],
            "_and_",
            [
              [
                "PROJECT.TASK.actualDuration", "eq", 0
              ],
              [
                "PROJECT.TASK.progress", "gt", 0
              ]
            ]
          ]
        ];
        
        if (this.tasks === null) {
          let tasksIds = await taskService.listProjectDashboard({ name: 'Project Task Critical Alerts', filter: criticalFilter, limit_data: true, limit: 100 }, this.project.uuId).then(resp => {
            return resp.data;
          }).catch(e => {
            // eslint-disable-next-line
            console.error(e);
          });
          
          let warningIds = await taskService.listProjectDashboard({ name: 'Project Task Warnings', filter: warningFilter, limit_data: true, limit: 100 }, this.project.uuId).then(resp => {
            return resp.data;
          }).catch(e => {
            // eslint-disable-next-line
            console.error(e);
          });
          
          tasksIds.push(...warningIds);
          if (tasksIds.length !== 0) {
            this.tasks = await taskService.listTasksDashboard(tasksIds.map(t => t.uuId)).then(response => {
              return response.data;
            }).catch(e => {
              // eslint-disable-next-line
              console.error(e);
              return [];
            });
          }
          else {
            this.tasks = [];
          }
        }
        
        this.tasks.forEach(function(task) {
          if (!self.showCompleted && task.progress == 1) {
            return;
          }
          const now = moment();
          const start = moment(task.startTime);
          const end = moment(task.endTime);
          const startMinus7 = moment(task.startTime).subtract(7,'d');
          const endMinus7 = moment(task.endTime).subtract(7,'d');
          let problems = [];

          if (now.isAfter(start) && task.progress == 0) {
            // Late starting
            problems.push({type: typeCritical, message: self.$t('task_alert.status.late_start')});
          } else if (now.isAfter(startMinus7) && task.progress == 0) {
            // Must start soon
            problems.push({type: typeWarning, message: self.$t('task_alert.status.start_soon')});
          }

          if (now.isAfter(end) && task.progress < 1) {
            // Late finishing
            problems.push({type: typeCritical, message: self.$t('task_alert.status.late_finish')});
          } else if (now.isAfter(endMinus7) && task.progress < 1) {
            // Must finish soon
            problems.push({type: typeWarning, message: self.$t('task_alert.status.finish_soon')});
          }
          
          if (task.actualCost > task.estimatedCost) {
            // Over budget
            problems.push({type: typeCritical, message: self.$t('task_alert.status.over_budget')});
          } else if (task.actualCost > (task.estimatedCost - (task.estimatedCost *.10))) {
            // Nearly over budget
            if (task.progress < 1) {
              // But only if the task isn't finished yet. If it finished and wasn't over budget,
              // that's a good thing!
              problems.push({type: typeWarning, message: self.$t('task_alert.status.nearly_over_budget')});
            }
          }
          
          // No staff assigned
          if (task.staffs.length == 0) {
            problems.push({type: typeWarning, message: self.$t('task_alert.status.no_staff')});
          }
 
          // No work effort entered
          if (task.progress > 0 && task.totalActualDuration == 0 && task.workEffort &&
              (task.workEffort.length === 0 || task.workEffort.filter(w => w !== 0).length === 0)) {
            problems.push({type: typeWarning, message: self.$t('task_alert.status.no_effort')});
          }

          // Try to get the first critical problem
          // If none, try to get the first warning problem
          let problem = problems.find(p => p.type == typeCritical);
          if (!problem) {
            problem = problems.find(p => p.type == typeWarning);
          }
          
          if (!problem) {
            return;
          }
          if (problem.type == typeWarning && !self.showWarnings) {
            return;
          }
          const color = problem.type == typeCritical ? red : orange;
          self.alertTasks.push({
            status: problem.message,
            color: color,
            name: [task.path, task.name].join(' / '),
            start: moment(task.startTime),
            end: moment(task.endTime),
            progress: task.progress,
            uuId: task.uuId,
            projectId: self.project.uuId
          });          
        });
        this.loaded = true;
      },
      saveSettings() {
        this.profile.showWarnings = this.showWarnings;
        this.profile.showCompleted = this.showCompleted;
        this.$emit('saveWidget', this.profile);
      },
      editTask(params) {
        this.$emit('editTask', params);
      }
    }
  }
</script>

<style scoped>
  .settings {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    flex-wrap: wrap;
  }
  .settings div {
    margin-left: 10px;
  }

  .center-text {
    text-align: center;
    margin: auto;
  }
</style>